.bill-page-container {
  margin-top: 100px;
  margin-left: 25%;
}
.bill-top {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 24px;
  max-width: 1200px;
}
.date-container {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bill-total {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important ;
  line-height: 24px !important;
  display: flex;
  flex-direction: row !important;
}
.last-row {
  background: #eeeeee;
  border: 1px solid #eeeeee;
}
.view-invoice-btn {
  width: 154px !important;
  height: 28px !important;
  background: #0575e6 !important;
  border-radius: 8px !important;
  color: white !important;
  text-transform: none !important;
  justify-content: space-evenly !important;
}
@media (max-width : 1200px) {
  .bill-page-container{
    margin-left: 20px;
    margin-right: 20px;
  }
  .bill-top{
    margin-left: 30px;
    margin-right: 30px;
  }
}