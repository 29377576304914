.org-div {
  display: flex;
  flex-direction: column;
  margin-left: 25%;
}
.add-org-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}
.helper-text {
  font-size: 10px;
  color: #9e9e9e;
}
.text-field-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.add-org-container span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.terms-container {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
  text-align: left;
  display: flex;
}
.table-container {
  max-width: 1200px;
  background: #ffffff !important;
  border: 1px solid #0575e6;
  border-radius: 8px !important;
}
.table-header {
  background: #0575e6;
  border-radius: 8px 8px 0px 0px !important;
}
.table-head-title {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 18px;
  color: #ffffff !important;
}
.table-body-text {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #6d747a !important;
}
.orgs-card-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  max-width: 550px;
}

@media (max-width: 1200px) {
  .org-div {
    margin-left: 0%;
    align-items: center;
  }
  .table-container {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .add-org-container {
    padding: 40px;
  }
}
