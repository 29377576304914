.tool-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tool-bar-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tool-bar-left > * {
  margin: 10px;
}
.tool-bar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.tool-bar-right > * {
  margin: 10px;
}
.logo-img {
  width: 40px;
  height: 40px;
  filter: invert(100%);
}
.logo-div {
  cursor: pointer;
}
.company-select {
  width: 450px;
}
.add-org-input{
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 15px;
    height: 30px;
    margin: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    padding: 18px;
    color: #333333;
}

input,
label {
  margin: 5px 5px;
}

.form-control {
  float: left;
}

#autocomplete {
  width: 100%;
}

#inputCity {
  width: 35%;
}

#inputState {
  width: 35%;
}

#inputZip {
  width: 24%;
}

#inputCounty {
  width: 100%;
}
.full-width {
    width: 100%;
}

.text-field-container :nth-child(3){
  width: 100%;
}

@media (max-width: 750px) {
  .company-select {
    width: 30vw;
  }
}
