.not-found-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.not-found-container > img {
    margin-top: 100px;
    width: 50vw;
}
.not-found-subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }