.org-details {
  margin-top: 100px;
  max-width: 1200px;
  display: flex;
}
.org-name {
  display: flex;
  flex-direction: column;
  flex: 2;
}
.truncate {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.org-details-card {
  width: 550px;
  height: 52px;
  padding: 0 24px 0 24px;
  margin-bottom: 16px;

  background: #ffffff;
  border: 1px solid #0447ae;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.org-details-card-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}
.org-details-card-detail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
  max-width: 350px;
}
.org-details-card-right-container {
  display: flex;
  align-items: center;
}
.send-ada {
  display: flex;
  justify-content: center;
}
.org-table-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
}
.individual-dialog {
  border-radius: 8px !important;
}
.dialog-cancel-btn {
  background: #ffffff !important;
  width: 141px !important;
  border: 1px solid #eeeeee !important;
  border-radius: 30px !important;
  color: #333333 !important;
  opacity: 0.6;
  text-transform: none !important;
}
.dialog-cancel-btn-red {
  background: #ffffff !important;
  width: 141px !important;
  border: 1px solid #f31b1b !important;
  border-radius: 30px !important;
  color: #f31b1b !important;
  opacity: 0.6;
  text-transform: none !important;
}
.dialog-submit-btn {
  background: #0575e6 !important;
  width: 141px !important;
  border-radius: 30px !important;
  text-transform: none !important;
  color: #ffffff !important;
  text-transform: none !important;
}
.individual-add-btn {
  background: #0575e6 !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: #ffffff !important;
  height: 27px !important;
  padding: 16px !important;

}

@media (max-width: 1200px) {
  .org-table-header-container{
    width: 90%;
    margin: 0px 5%;
  }
  
}

@media (max-width: 950px) {
  .org-details {
    flex-direction: column;
  }
}
@media (max-width: 650px) {
  .org-details-card {
    width: 350px;
    height: 70px;
  }
  .org-details-card-detail {
    width: 200px;
    word-wrap: break-word;
  }
}
