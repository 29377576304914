.auth-container {
    display: flex;
    height: 100vh;
}

.auth-container-right {
    flex: 4;
    background: linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-container-left {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-container-left>h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 39px;
    color: #333333;
}

.auth-container-left>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
}

.text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text-block-2{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-block>h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
}

.text-block> div >p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
}

.learn-more-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.learn-more-btn {
    width: 135px;
    height: 37px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-transform: none !important;
    color: #FFFFFF !important;
    background: #0575E6 !important;
    border-radius: 20px !important;
    margin: 5px !important
}

.auth-container-right>h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 39px;
    color: #333333;
}

.auth-container-right>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
}

.auth-input {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 30px;
    width: 307px;
    height: 60px;
    margin: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    padding: 18px;
    color: #333333;
}

.auth-btn {
    width: 307px;
    height: 57px;
    background: #0575E6 !important;
    border-radius: 30px !important;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px;
    color: #FFFFFF !important;
    margin-top: 10px !important;
    text-transform: none !important;
}
.google-logo{
    margin-right: 5px;
    max-width: 40px;
}
.auth-right-svg{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 20%;
    height: 30%;
}

@media only screen and (max-width: 900px) {
    .auth-container {
        flex-direction: column-reverse;
    }
    .auth-container-right{
        flex: 5;
    }
    .auth-container-left{
        flex: 7;
        justify-content: flex-start;
        margin-top: 5%;
    }
    .text-block > img {
        max-width: 60%;
    }
    .auth-right-svg{
        width: 50%;
        height: 30%;
    }
}