.get-help-container {
  margin-top: 100px;
  margin-left: 30%;
  display: flex;
  flex-direction: column;
}
.want-help-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #000000;
}
.want-help-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #000000;
  width: 45vw;
}
.get-help-input {
  border: 1px solid #eeeeee !important;
  border-radius: 30px !important;
  height: 30px;
  padding: 12px !important;
  margin: 8px !important;
  width: 45vw;
}
@media (max-width: 1200px) {
  .get-help-container{
    margin-left: 5%;
  }
  .want-help-subtitle{
    width: 90vw;
  }
  .get-help-input{
    width: 85vw;
  }
  
}
