.dashboard-container {
  margin-top: 120px;
  width: 50%;
  margin-left: 20%;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.welcome-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
}

.welcome-text-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 24px;
  color: #000000;
}
.analytics-card-container {
  display: flex;
}
.analytics-card {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(179, 179, 179, 0.3) !important;
  border-radius: 16px !important;
  padding: 25px;
  margin: 25px;
  width: 312px;
}
.analytics-card-title-container {
  display: flex;
  flex-direction: column;
}
.analytics-card-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 8px;
}
.analytics-card-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #939ca3;
  margin-bottom: 8px;
}
.graph-container {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(179, 179, 179, 0.3) !important;
  border-radius: 16px !important;
  height: 55vh;
  margin: 25px;
  width: 1200px;
}
.this-month-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-top: 12px;
  color: #000000;
}
.this-month-box {
  width: 24.22px;
  height: 22.76px;
  background: #48b4ff;
  border-radius: 4px;
  margin: 16px;
}
.last-month-box {
  width: 24.22px;
  height: 22.76px;
  background: #ffcd9e;
  border-radius: 4px;
  margin: 16px;
}

@media (max-width: 900px) {
  .dashboard-container {
    align-items: center;
    width: 70%;
    margin-left: auto;
  }
  .welcome-text-2 {
    text-align: center;
  }
  .welcome-text {
    text-align: center;
  }
}
