.side-nav-btn-active{
    width: 154px !important;
    height: 52px !important;
    background: #FFFFFF !important;
    border-radius: 38px !important;
    text-transform: none !important;
    justify-content: flex-start !important;
}
.side-nav-btn-active > * {
    padding: 5px;
}
.side-nav-btn {
    color: #FFFFFF !important;
    width: 154px !important;
    height: 52px !important;
    border-radius: 38px !important;
    padding: 8px !important;
    border-color: #FFFFFF !important;
    text-transform: none !important;
    justify-content: flex-start !important;
}
.side-nav-btn > * {
    padding: 5px;
}